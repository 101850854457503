@tailwind base;

@layer base {
    html {
        font-size: 14px;
    }

    a {
        @apply text-mwteal;
    }

    input[type="text"], input[type="email"], input[type="number"], textarea {
        @apply p-2;
        @apply border;
        @apply border-mwpurple;
        @apply w-full;
    }

    button, button[type="button"] {
        @apply cursor-pointer;
        @apply px-4;
        @apply py-2;
        @apply bg-white;
        @apply inline-block;
        @apply rounded-sm;
        @apply border;
        @apply border-mwcharcoal;
        @apply text-mwcharcoal;
    }

    button:hover {
        @apply text-mwteal;
        @apply border-mwteal;
    }
}

@tailwind components;

@tailwind utilities;